import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { LocalizedLink } from "gatsby-theme-i18n"
import { GatsbyImage } from "gatsby-plugin-image"

const Index = ({ data }) => {
  const [activeTag, setActiveTag] = React.useState("")

  const [showMore, setShowMore] = React.useState(false)

  const toggleActiveTag = tag => {
    setActiveTag(e => (e === tag ? "" : tag))
  }

  const { description, title } = data.pageData.frontmatter
  const posts = data.posts.nodes

  const tags = [
    ...new Set(posts.map(node => node.childMdx.frontmatter.category)),
  ]

  const filteredPosts =
    activeTag === ""
      ? posts
      : posts.filter(p => p.childMdx.frontmatter.category === activeTag)

  return (
    <Layout>
      <Seo title={title} description={description} />
      <div className="shell">
        <h1>
          {title}
          <span class="dotRed">.</span>
        </h1>

        <p
          dangerouslySetInnerHTML={{ __html: description }}
          className="description"
        />

        <div className="insights__tags">
          {tags.map(tag => (
            <button
              className={`insights__tags_single ${activeTag === tag && "active"}`}
              onClick={() => toggleActiveTag(tag)}
              key={tag}
            >
              {tag}
            </button>
          ))}
        </div>
        <div className="insights__grid">
          {filteredPosts.map((node, index) => {
            const {
              slug,
              title,
              featuredImage,
              category,
              author,
              author_image,
              job,
              date,
            } = node.childMdx.frontmatter
            const { excerpt } = node.childMdx
            return (
              <LocalizedLink
                to={slug}
                key={`insights_post_${index}`}
                style={{ textDecoration: "none" }}
              >
                <div className="insights__grid_post">
                  <GatsbyImage
                    image={featuredImage.childImageSharp.gatsbyImageData}
                    alt={title}
                    className="insights__grid_post-image"
                  />
                  <div className="insights__grid_post-text">
                    <div>
                      <p className="insights__grid_post-info">
                        <strong>{category}</strong> - {date}
                      </p>
                      <h4 className="insights__grid_post-title">{title}</h4>
                      <p className="insights__grid_post-excerpt">{excerpt}</p>
                    </div>
                    <div className="insights__grid_post-author">
                      <GatsbyImage
                        image={author_image.childImageSharp.gatsbyImageData}
                        alt={title}
                        className="insights__grid_post-author-image"
                      />
                      <div>
                        <h5 className="insights__grid_post-author-name">
                          {author}
                        </h5>
                        <p className="insights__grid_post-author-job">{job}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </LocalizedLink>
            )
          })}
        </div>
        {!showMore && filteredPosts.length > 4 && (
          <div className="insights__view-more">
            <button
              className="insights__view-more_button"
              onClick={() => setShowMore(true)}
            >
              Voir plus
            </button>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query ($locale: String!) {
    pageData: mdx(
      fileAbsolutePath: { regex: "/static-pages/insights/" }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
      }
    }
    posts: allFile(
      sort: { fields: childMdx___frontmatter___date, order: DESC }
      filter: {
        sourceInstanceName: { eq: "insights" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      nodes {
        childMdx {
          excerpt(pruneLength: 500)
          frontmatter {
            slug
            title
            category
            featuredImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            author
            author_image {
              childImageSharp {
                gatsbyImageData
              }
            }
            job
            date(formatString: "D MMMM YYYY", locale: $locale)
          }
        }
      }
    }
  }
`
